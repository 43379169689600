// settings.dev.ts  Used in the azure dev environment

import { AppSettings } from "./AppSettings";

export const config: AppSettings = {
    clientId: "66ab54cc-2cbc-4a48-a978-a34750051338",
    pgsApi: "https://pgs-wrapper-dev-cvx.azurewebsites.net/api/pgs",
    tgsApi: "https://tgs-wrapper-dev.azurewebsites.net/api/tgs",
    pgsWrapperScope: "https://explore-test.azure.chevron.com/user_impersonation",
    tgsWrapperScope: "https://explore-test.azure.chevron.com/user_impersonation"
}